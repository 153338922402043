import React, { Component } from 'react';
import { Link } from 'gatsby';
import Layout from "layout";

class NotFound extends Component {
    render() {
        return (
            <Layout>
                <div id="NotFound">
                    <div className="container">
                        <h1>404</h1>
                        <div>
                            <span>Maaf, Halaman yang kamu cari tidak tersedia</span>
                            <br/>
                            <Link to="/">kembali ke halaman utama</Link>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default NotFound;